import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import { ThemeProvider } from "./context/ThemeContext";
import "./fonts.css";

// Suppress specific warnings
const suppressWarnings = (msg) => {
  // Check if msg is a string and then check includes
  if (typeof msg === "string") {
    return (
      msg.includes("Module not found: Error: Can't resolve") ||
      msg.includes("BREAKING CHANGE: webpack < 5 used to include polyfills")
    );
  }
  // Return false for non-string messages to avoid suppression
  return false;
};

const originalWarn = console.warn;
console.warn = (msg, ...args) => {
  if (!suppressWarnings(msg)) {
    originalWarn(msg, ...args);
  }
};

const originalError = console.error;
console.error = (msg, ...args) => {
  if (!suppressWarnings(msg)) {
    originalError(msg, ...args);
  }
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
