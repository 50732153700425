// src/pages/ProfilePage.js

import React, { useState, useEffect, useContext } from "react";
import {
  Window,
  WindowContent,
  WindowHeader,
  Button,
  Tabs,
  Tab,
  TabBody,
  Avatar,
  Frame,
  GroupBox,
  Counter,
  ProgressBar,
} from "react95";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CompetitionCreator from "../components/CompetitionCreator";
import ProfileAvatarSelector from "../components/ProfileAvatarSelector";
import { ThemeContext } from "../context/ThemeContext"; // Import ThemeContext

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Styled Components
const ProfileWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;

const ProfileWindow = styled(Window)`
  height: auto;
  width: 60%;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1290px) {
    width: 98%;
  }
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 2%;
`;

const WindowContentStyled = styled(WindowContent)`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const WindowContentStyled2 = styled(WindowContent)`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TicketsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  justify-content: space-evenly;
`;

const TicketCard = styled(Window)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TokenAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ContractAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 0 10px;
`;

const InfoGroup = styled(GroupBox)`
  margin: 0 10px;
  height: 100%;
  padding: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    margin: 5px;
    font-size: 1rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const ProgressWrapper = styled.div`
  width: calc(100% - 20px);
  margin: 10px 10px 0 10px;
`;

const CompetitionList = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr;
`;

const TokenNameWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1rem;
    font-weight: bold;
  }
`;

const NestedTabBody = styled(TabBody)`
  width: 100%;
  @media (max-width: 512px) {
    width: 113%;
  }
`;

const LargeButton = styled(Button)`
  font-size: 2rem;
  padding: 1rem 1rem;
  width: 25%;
  height: 50px;
  variant: primary;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Helper Functions
const formatPrizeAmount = (amount) => {
  const num = parseFloat(amount);
  return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
};

const calculateRemainingTime = (endTime) => {
  const now = new Date().getTime();
  const distance = new Date(endTime).getTime() - now;

  if (distance < 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

const formatTime = (time) => {
  return `${time.days.toString().padStart(2, "0")}${time.hours
    .toString()
    .padStart(2, "0")}${time.minutes.toString().padStart(2, "0")}${time.seconds
    .toString()
    .padStart(2, "0")}`;
};

const truncateAddress = (address) =>
  `${address.slice(0, 5)}...${address.slice(-5)}`;

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [userCompetitions, setUserCompetitions] = useState([]);
  const [ticketCompetitions, setTicketCompetitions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTicketTab, setActiveTicketTab] = useState(0);
  const [feePercentages, setFeePercentages] = useState(null); // State for fee percentages
  const { wallet_address } = useParams();
  const navigate = useNavigate();
  const { changeTheme } = useContext(ThemeContext); // Use changeTheme from context

  // Fetch fee percentages from backend
  useEffect(() => {
    const fetchFeePercentages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/fee-percentages`);
        setFeePercentages(response.data);
      } catch (error) {
        console.error("Error fetching fee percentages:", error);
      }
    };
    fetchFeePercentages();
  }, []);

  // Fetch user profile, tickets, and competitions
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/users/profile/${wallet_address}`
        );
        setProfile(response.data);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    const fetchTickets = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/users/${wallet_address}/entries`
        );
        console.log("Fetched tickets:", response.data);
        setTickets(response.data);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    const fetchUserCompetitions = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/users/${wallet_address}/competitions`
        );
        let competitions = response.data;

        // Current time for comparison
        const now = new Date().getTime();

        // Sort competitions based on the defined criteria
        competitions.sort((a, b) => {
          // 1. Determine if competitions can be claimed
          const aCanClaim =
            a.status === "completed" && parseFloat(a.creator_pending_fee) > 0;
          const bCanClaim =
            b.status === "completed" && parseFloat(b.creator_pending_fee) > 0;

          if (aCanClaim && !bCanClaim) return -1;
          if (!aCanClaim && bCanClaim) return 1;

          // 2. Determine if competitions are ongoing
          const aEndTime = new Date(a.end_time).getTime();
          const bEndTime = new Date(b.end_time).getTime();

          const aIsOngoing = aEndTime > now;
          const bIsOngoing = bEndTime > now;

          if (aIsOngoing && bIsOngoing) {
            // Both ongoing: sort by ascending remaining time
            return aEndTime - bEndTime;
          }

          if (aIsOngoing && !bIsOngoing) {
            // a ongoing, b ended: a comes before b
            return -1;
          }

          if (!aIsOngoing && bIsOngoing) {
            // b ongoing, a ended: b comes before a
            return 1;
          }

          // 3. Both ended and cannot be claimed: sort by descending creation time
          const aCreatedAt = new Date(a.createdAt || a.created_at).getTime();
          const bCreatedAt = new Date(b.createdAt || b.created_at).getTime();

          return bCreatedAt - aCreatedAt;
        });

        setUserCompetitions(competitions);
      } catch (error) {
        console.error("Error fetching competitions:", error);
      }
    };

    fetchProfile();
    fetchTickets();
    fetchUserCompetitions();
  }, [wallet_address]);

  useEffect(() => {
    const fetchTicketCompetitions = async () => {
      if (tickets.length === 0) {
        setTicketCompetitions([]);
        return;
      }

      try {
        const competitionIds = tickets.map((ticket) => ticket.competition_id);
        const uniqueCompetitionIds = [...new Set(competitionIds)];

        const responses = await Promise.all(
          uniqueCompetitionIds.map((id) =>
            axios
              .get(`${API_BASE_URL}/competitions/${id}`)
              .then((response) => response.data)
              .catch((error) => {
                console.error(
                  `Error fetching competition with id ${id}:`,
                  error
                );
                return null;
              })
          )
        );

        const validCompetitions = responses.filter((comp) => comp != null);

        setTicketCompetitions(validCompetitions);
      } catch (error) {
        console.error("Error fetching competitions:", error);
      }
    };

    fetchTicketCompetitions();
  }, [tickets]);

  useEffect(() => {
    const updateRemainingTimes = () => {
      setTickets((currentTickets) =>
        currentTickets.map((ticket) => {
          const competition = ticketCompetitions.find(
            (comp) => comp.id === ticket.competition_id
          );
          const remainingTime = competition
            ? calculateRemainingTime(competition.end_time)
            : null;
          return { ...ticket, remainingTime };
        })
      );
    };

    const interval = setInterval(updateRemainingTimes, 1000);

    return () => clearInterval(interval);
  }, [ticketCompetitions]);

  const handleChangeTab = (value) => {
    setActiveTab(value);
  };

  const handleChangeTicketTab = (value) => {
    setActiveTicketTab(value);
  };

  const handleRefundClaim = async (ticket) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/entries/${ticket.id}/refund`,
        {
          user_id: profile.wallet_address,
        }
      );

      if (response.status === 200) {
        alert("Refund claimed successfully!");

        // Update the ticket's refund status
        setTickets((prevTickets) =>
          prevTickets.map((t) =>
            t.id === ticket.id
              ? { ...t, refund_pending: false, refund_amount: 0 }
              : t
          )
        );
      }
    } catch (error) {
      console.error("Error claiming refund:", error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Error: ${error.response.data.error}`);
      } else {
        alert("Error claiming refund. Please try again.");
      }
    }
  };

  const handleCompetitionClick = (id) => {
    navigate(`/competition/${id}`);
  };

  // Handle competition updates after a fee is claimed
  const handleFeeClaimed = (updatedCompetition) => {
    setUserCompetitions((prevCompetitions) =>
      prevCompetitions.map((comp) =>
        comp.id === updatedCompetition.id ? updatedCompetition : comp
      )
    );
  };

  // Function to update the profile state
  const updateProfile = (newData) => {
    setProfile((prevProfile) => ({ ...prevProfile, ...newData }));
  };

  let filteredTickets = tickets.filter((ticket) => {
    const competition = ticketCompetitions.find(
      (comp) => comp.id === ticket.competition_id
    );
    if (activeTicketTab === 0) {
      return competition && new Date(competition.end_time) >= new Date();
    } else if (activeTicketTab === 1) {
      return competition && new Date(competition.end_time) < new Date();
    } else if (activeTicketTab === 2) {
      return false;
    } else if (activeTicketTab === 3) {
      const progressPercentage = competition
        ? Math.min(
            (
              (competition.prize_pool /
                (competition.winners_count * competition.ticket_price * 2)) *
              100
            ).toFixed(1),
            100
          )
        : 0;
      return (
        competition &&
        new Date(competition.end_time) >= new Date() &&
        progressPercentage >= 100
      );
    } else if (activeTicketTab === 4) {
      const progressPercentage = competition
        ? Math.min(
            (
              (competition.prize_pool /
                (competition.winners_count * competition.ticket_price * 2)) *
              100
            ).toFixed(1),
            100
          )
        : 0;
      return (
        competition &&
        new Date(competition.end_time) < new Date() &&
        progressPercentage < 100
      );
    }
    return false;
  });

  if (activeTicketTab === 3) {
    filteredTickets = filteredTickets.sort((a, b) => {
      const competitionA = ticketCompetitions.find(
        (comp) => comp.id === a.competition_id
      );
      const competitionB = ticketCompetitions.find(
        (comp) => comp.id === b.competition_id
      );
      return new Date(competitionA.end_time) - new Date(competitionB.end_time);
    });
  }

  if (!profile) {
    return <p>Loading...</p>;
  }

  return (
    <ProfileWrapper>
      <ProfileWindow>
        <WindowHeaderStyled>
          <span>ProfilePage.eXe</span>
          <Button
            onClick={() => navigate(-1)}
            style={{ marginLeft: "auto", padding: "0 10px" }}
          >
            X
          </Button>
        </WindowHeaderStyled>

        <Tabs value={activeTab} onChange={handleChangeTab}>
          <Tab value={0}>Profile</Tab>
          <Tab value={1}>Tickets</Tab>
          <Tab value={2}>Competitions</Tab>
        </Tabs>

        <TabBody>
          {/* Profile Tab */}
          {activeTab === 0 && (
            <ProfileAvatarSelector
              walletAddress={wallet_address}
              initialAvatar={profile.avatar_url}
              initialTheme={profile.theme} // Pass the saved theme
              updateProfile={updateProfile}
            />
          )}

          {/* Tickets Tab */}
          {activeTab === 1 && (
            <WindowContentStyled>
              <Tabs value={activeTicketTab} onChange={handleChangeTicketTab}>
                <Tab value={0}>New</Tab>
                <Tab value={3}>Live</Tab>
                <Tab value={1}>Finished</Tab>
                <Tab value={2}>Winners</Tab>
                <Tab value={4}>Refunds</Tab>
              </Tabs>
              <NestedTabBody>
                {filteredTickets.length > 0 ? (
                  <TicketsRow>
                    {filteredTickets.map((ticket) => {
                      const competition = ticketCompetitions.find(
                        (comp) => comp.id === ticket.competition_id
                      );

                      if (!competition) return null;

                      const timeString = ticket.remainingTime
                        ? formatTime(ticket.remainingTime)
                        : "00000000";
                      const progressPercentage = competition
                        ? Math.min(
                            (
                              (competition.prize_pool /
                                (competition.winners_count *
                                  competition.ticket_price *
                                  2)) *
                              100
                            ).toFixed(1),
                            100
                          )
                        : 0;
                      const poolPercentage = competition.pool_percentage;
                      const tokenBuyPercentage =
                        competition.token_buy_percentage;
                      const protocolFeePercentage =
                        competition.total_fees_percentage;

                      return (
                        <TicketCard key={ticket.id}>
                          <WindowHeaderStyled>
                            {competition.token_symbol}_{tokenBuyPercentage}_
                            {poolPercentage}_{protocolFeePercentage}.exe
                            <Counter value={timeString} size="sm" />
                          </WindowHeaderStyled>
                          <TokenNameWrapper>
                            <p>{competition.token_name}</p>
                          </TokenNameWrapper>
                          <WindowContentStyled2>
                            <TokenAvatar>
                              <img
                                src={competition.token_icon}
                                alt={competition.token_symbol}
                              />
                            </TokenAvatar>
                            <ContractAddress>
                              {competition &&
                                competition.token &&
                                truncateAddress(competition.token)}
                              <CopyToClipboard
                                text={
                                  competition ? competition.token : "No Address"
                                }
                                onCopy={() => alert("Copied to clipboard!")}
                              >
                                <FaCopy className="copy-icon" />
                              </CopyToClipboard>
                            </ContractAddress>

                            {activeTicketTab === 4 ? (
                              <>
                                <InfoGroup label="Tickets">
                                  <p>{ticket.ticket_count}</p>
                                </InfoGroup>
                                <InfoGroup label="Refund">
                                  <p>
                                    {formatPrizeAmount(ticket.refund_amount)}{" "}
                                    SOL
                                  </p>
                                </InfoGroup>
                                <LargeButton
                                  onClick={() => handleRefundClaim(ticket)}
                                  disabled={
                                    !ticket.refund_pending ||
                                    parseFloat(ticket.refund_amount) <= 0
                                  }
                                >
                                  {ticket.refund_pending ? "Claim" : "Refunded"}
                                </LargeButton>
                              </>
                            ) : (
                              <>
                                <InfoGroup label="Jackpot">
                                  <p>
                                    {competition
                                      ? formatPrizeAmount(
                                          competition.prize_pool
                                        )
                                      : "0"}{" "}
                                    SOL
                                  </p>
                                </InfoGroup>
                                <InfoGroup label="Winners">
                                  <p>
                                    {competition
                                      ? competition.winners_count
                                      : "0"}
                                  </p>
                                </InfoGroup>
                                <InfoGroup label="Tickets">
                                  <p>{ticket.ticket_count}</p>
                                </InfoGroup>
                              </>
                            )}
                          </WindowContentStyled2>
                          <ProgressWrapper>
                            <ProgressBar value={progressPercentage} />
                          </ProgressWrapper>
                        </TicketCard>
                      );
                    })}
                  </TicketsRow>
                ) : (
                  <p>No tickets found for this category.</p>
                )}
              </NestedTabBody>
            </WindowContentStyled>
          )}

          {/* Competitions Tab */}
          {activeTab === 2 && (
            <WindowContentStyled>
              <CompetitionList>
                {userCompetitions.length > 0 ? (
                  userCompetitions.map((competition) => (
                    <div
                      key={competition.id}
                      onClick={() => handleCompetitionClick(competition.id)}
                      style={{ position: "relative", width: "100%" }}
                    >
                      <CompetitionCreator
                        competition={competition}
                        updateCompetitions={handleFeeClaimed}
                      />
                    </div>
                  ))
                ) : (
                  <p>No competitions found.</p>
                )}
              </CompetitionList>
            </WindowContentStyled>
          )}
        </TabBody>
      </ProfileWindow>
    </ProfileWrapper>
  );
};

export default ProfilePage;
