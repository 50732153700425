// src/pages/CompetitionDetailsPage.js

import React, { useState, useEffect } from "react";
import {
  Window,
  WindowContent,
  WindowHeader,
  Avatar,
  Frame,
  Counter,
  GroupBox,
  ProgressBar,
  Button,
  NumberInput,
  Tabs,
  Tab,
  TabBody,
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "react95";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";

import axios from "axios";
import { useWallet } from "@solana/wallet-adapter-react";
import CompetitionStyles from "../styles/CompetitionStyles";
import styled from "styled-components";

// Add this line to use the API_BASE_URL environment variable
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

const CompetitionDetailsPage = () => {
  const { id } = useParams();
  const [competition, setCompetition] = useState(null);
  const [entries, setEntries] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { publicKey } = useWallet();
  const [ticketAmount, setTicketAmount] = useState(1);
  const [counterSize, setCounterSize] = useState("lg");
  const [activeTab, setActiveTab] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false); // State for confirmation window

  const fetchCompetition = async () => {
    try {
      // Updated to use API_BASE_URL
      const response = await axios.get(`${API_BASE_URL}/competitions/${id}`);
      setCompetition(response.data);
      setRemainingTime(calculateRemainingTime(response.data.end_time));
    } catch (error) {
      console.error("Error fetching competition:", error);
    }
  };

  const fetchEntries = async () => {
    try {
      // Updated to use API_BASE_URL
      const response = await axios.get(
        `${API_BASE_URL}/competitions/${id}/entries`
      );
      setEntries(response.data);
    } catch (error) {
      console.error("Error fetching entries:", error);
    }
  };

  useEffect(() => {
    fetchCompetition();
    fetchEntries();
  }, [id]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (competition) {
        setRemainingTime(calculateRemainingTime(competition.end_time));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [competition]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      if (window.innerWidth <= 768) {
        setCounterSize("m");
      } else {
        setCounterSize("lg");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!competition) {
    return <div>Loading...</div>;
  }

  const calculateRemainingTime = (endTime) => {
    const now = new Date().getTime();
    const distance = new Date(endTime).getTime() - now;

    if (distance < 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const truncateAddress = (address) =>
    `${address.slice(0, 5)}...${address.slice(-5)}`;

  const getAddressDisplay = (address, isSmallScreen) =>
    isSmallScreen ? truncateAddress(address) : address;

  const formatTime = (time) => {
    return `${time.days.toString().padStart(2, "0")}${time.hours
      .toString()
      .padStart(2, "0")}${time.minutes
      .toString()
      .padStart(2, "0")}${time.seconds.toString().padStart(2, "0")}`;
  };

  const timeString = remainingTime ? formatTime(remainingTime) : "00000000";
  const minimumPool = competition.minimum_pool;
  const currentAmount = competition.prize_pool;
  const progressPercentage = Math.min(
    ((currentAmount / minimumPool) * 100).toFixed(1),
    100
  );

  const handleInputChange = (value) => {
    if (!isNaN(value) && value > 0) {
      setTicketAmount(value);
    } else {
      setTicketAmount(1);
    }
  };

  const handleChangeTab = (value) => {
    setActiveTab(value);
  };

  const handleClose = () => {
    if (location.state?.fromCreateCompetition) {
      // If the user came from the create-competition page, go to the home page
      navigate("/");
    } else {
      // Otherwise, go back to the previous page
      navigate(-1);
    }
  };

  const handleBuyClick = () => {
    setShowConfirmation(true); // Show confirmation window
  };

  const handleConfirmBuy = async () => {
    setShowConfirmation(false); // Close the window
    if (!publicKey) {
      alert("Please connect your wallet to buy tickets.");
      return;
    }

    try {
      // Updated to use API_BASE_URL
      const response = await axios.post(
        `${API_BASE_URL}/competitions/${id}/entries`,
        {
          user_id: publicKey.toBase58(),
          ticket_count: ticketAmount,
        }
      );

      if (response.status === 201) {
        alert("Tickets purchased successfully!");
        fetchCompetition();
        fetchEntries();
      }
    } catch (error) {
      console.error("Error buying tickets:", error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Error buying tickets: ${error.response.data.error}`);
      } else {
        alert("Error buying tickets. Please try again.");
      }
    }
  };

  const handleCancelBuy = () => {
    setShowConfirmation(false); // Close the window
  };

  const ticketPrice = competition.ticket_price;
  const totalCost = (ticketPrice * ticketAmount).toFixed(2);
  const poolPercentage = competition.pool_percentage;
  const tokenBuyPercentage = competition.token_buy_percentage;
  const protocolFeePercentage = competition.total_fees_percentage;

  const userTickets = entries.reduce(
    (acc, entry) =>
      entry.user_id === publicKey?.toBase58() ? acc + entry.ticket_count : acc,
    0
  );
  const uniqueParticipants = new Set(entries.map((entry) => entry.user_id))
    .size; // Count unique user IDs
  const totalTicketsSold = entries.reduce(
    (acc, entry) => acc + entry.ticket_count,
    0
  );

  return (
    <div>
      <CompetitionStyles />
      <div className="competition-item-wrapper">
        <Window className="competition-window">
          <WindowHeader className="window-header-styled">
            <span>
              {competition.token_symbol}_{tokenBuyPercentage}_{poolPercentage}_
              {protocolFeePercentage}.exe
            </span>
            <Button
              onClick={handleClose}
              style={{ marginLeft: "auto", padding: "0 10px" }}
            >
              X
            </Button>
          </WindowHeader>

          <Tabs value={activeTab} onChange={handleChangeTab}>
            <Tab value={0}>Competition</Tab>
            <Tab value={1}>Participants</Tab>
            <Tab value={2}>Chat Room</Tab>
          </Tabs>
          <TabBody>
            {activeTab === 0 && (
              <WindowContent className="window-content-styled">
                <img
                  // Updated to use API_BASE_URL
                  src={`${API_BASE_URL}/images/${competition.id}.png`}
                  alt={competition.token_symbol}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                    paddingBottom: "2%",
                  }}
                />

                <div className="token-name-wrapper">
                  <p>{competition.token_name}</p>
                </div>
                <div className="token-text-wrapper">
                  <p>{competition.description}</p>
                </div>
                <div className="competition-content-2">
                  <div className="competition-content">
                    <div className="token-details">
                      <Avatar className="token-avatar">
                        <img
                          src={competition.token_icon}
                          alt={competition.token_symbol}
                        />
                      </Avatar>

                      <p>
                        <div className="contract-address">
                          {truncateAddress(competition.token)}
                          <CopyToClipboard
                            text={competition.token}
                            onCopy={() => setCopied(true)}
                          >
                            <FaCopy className="copy-icon" />
                          </CopyToClipboard>
                        </div>
                      </p>
                    </div>
                    <div className="competition-details">
                      <GroupBox
                        className="group-box-styled"
                        label="Ticket Price"
                      >
                        <p>{competition.ticket_price} SOL</p>
                      </GroupBox>
                      <GroupBox className="group-box-styled" label="Winners">
                        <p>{competition.winners_count}</p>
                      </GroupBox>
                    </div>
                  </div>

                  <div className="wrapper">
                    <div className="competition-details-2">
                      <GroupBox className="group-box-styled-2" label="Jackpot">
                        <p>
                          {competition && competition.prize_pool
                            ? `${
                                Number(competition.prize_pool) % 1 === 0
                                  ? Number(competition.prize_pool).toFixed(0)
                                  : Number(competition.prize_pool).toFixed(2)
                              } SOL`
                            : "Loading..."}
                        </p>
                      </GroupBox>
                    </div>
                  </div>
                </div>
                <div className="responsive-frame">
                  <Frame
                    variant="outside"
                    className="countdown-timer"
                    style={{
                      margin: "1rem",
                      padding: ".5rem",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "row",
                      minWidth: "270px",
                    }}
                  >
                    <div className="countdown-timer">
                      TIME LEFT
                      <Frame className="styled-frame">
                        <Counter value={timeString} size={counterSize} />
                      </Frame>
                      <div className="progress-wrapper">
                        PROGRESS
                        <ProgressBar value={progressPercentage} />
                      </div>
                    </div>
                  </Frame>

                  <div className="wrapper-2">
                    <GroupBox
                      className="group-box-styled-3"
                      label="Total Tickets"
                    >
                      <div className="number-input-wrapper">
                        <NumberInput
                          value={ticketAmount}
                          step={1}
                          min={1}
                          max={1000}
                          onChange={(value) => handleInputChange(value)}
                          width={130}
                        />
                      </div>
                    </GroupBox>

                    <Button
                      className="large-button"
                      primary
                      onClick={handleBuyClick}
                    >
                      BUY
                    </Button>
                  </div>
                </div>
              </WindowContent>
            )}

            {activeTab === 1 && (
              <WindowContent className="window-content-styled">
                <div className="group-boxes-container">
                  <GroupBox
                    className="group-box-styled-responsive"
                    label="Your Tickets"
                  >
                    <p>{publicKey ? userTickets : "?"}</p>
                  </GroupBox>
                  <GroupBox
                    className="group-box-styled-responsive"
                    label="Total Participants"
                  >
                    <p>{uniqueParticipants}</p>
                  </GroupBox>
                  <GroupBox
                    className="group-box-styled-responsive"
                    label="Total Tickets"
                  >
                    <p>{totalTicketsSold}</p>
                  </GroupBox>
                </div>

                <Table className="custom-table">
                  <TableHead className="custom-thead">
                    <TableRow className="custom-tr">
                      <TableHeadCell className="custom-th">User</TableHeadCell>
                      <TableHeadCell className="custom-th">Tx ID</TableHeadCell>
                      <TableHeadCell className="custom-th">
                        Tickets
                      </TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="custom-tbody">
                    {entries.map((entry, index) => (
                      <TableRow key={index} className="custom-tr">
                        <TableDataCell className="custom-td">
                          <Link to={`/profile/${entry.user_id}`}>
                            {getAddressDisplay(entry.user_id, isSmallScreen)}
                          </Link>
                        </TableDataCell>
                        <TableDataCell className="custom-td"> - </TableDataCell>
                        <TableDataCell className="custom-td">
                          {entry.ticket_count}
                        </TableDataCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </WindowContent>
            )}

            {activeTab === 2 && (
              <WindowContent className="window-content-styled">
                <p>Coming Soon!</p>
              </WindowContent>
            )}
          </TabBody>
        </Window>
      </div>

      {showConfirmation && (
        <div className="PopupWrapper">
          <Window style={{ maxWidth: "400px", width: "95%", minHeight: 200 }}>
            <WindowHeader className="window-header-styled">
              <span>ConfirmPurchase.eXe</span>
              <Button
                onClick={handleCancelBuy}
                style={{ marginLeft: "auto", padding: "0 10px" }}
              >
                X
              </Button>
            </WindowHeader>
            <WindowContent>
              <div className="group-boxes-container2">
                <GroupBox className="group-box-styled" label="Ticket Price">
                  <p>{competition.ticket_price} SOL</p>
                </GroupBox>
                <GroupBox className="group-box-styled" label="Split %">
                  {tokenBuyPercentage}% / {poolPercentage}% /{" "}
                  {protocolFeePercentage}%
                </GroupBox>
              </div>
              <div className="group-boxes-container2">
                <GroupBox className="group-box-styled" label="Buy Tickets">
                  <p>{ticketAmount}</p>
                </GroupBox>

                <GroupBox className="group-box-styled" label="Total Cost">
                  <p>{totalCost} SOL</p>
                </GroupBox>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Button onClick={handleConfirmBuy} style={{ width: "100px" }}>
                  Confirm
                </Button>
              </div>
            </WindowContent>
          </Window>
        </div>
      )}
    </div>
  );
};

export default CompetitionDetailsPage;
