// src/components/CompetitionCreator.js

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Window,
  WindowContent,
  WindowHeader,
  Avatar,
  Frame,
  Counter,
  GroupBox,
  ProgressBar,
  Button,
} from "react95";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWallet } from "@solana/wallet-adapter-react";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Styled Components
const CompetitionItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CompetitionWindow = styled(Window)`
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer; /* Indicates that the window is clickable */
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`;

const CountdownTimer = styled.div`
  display: flex;
  align-items: center;
  max-height: 100%;
  font-size: 0.85rem;
`;

const StyledFrame = styled(Frame)`
  display: flex;
`;

const WindowContentStyled = styled(WindowContent)`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const WindowContentStyled2 = styled(WindowContent)`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 0px;
  }
`;

const CompetitionContent = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TokenAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const CompetitionDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }

  p {
    font-weight: bold;
  }
`;

const CompetitionDetails2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  p {
    font-size: clamp(1rem, 2.13vw, 2.13rem); /* Dynamic font size */
    font-weight: bold;
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides any overflowing content */
    text-overflow: ellipsis; /* Displays '...' if text overflows */
    max-width: 100%; /* Ensures text stays within the container */
  }
`;

const ContractAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const GroupBoxStyled = styled(GroupBox)`
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100px;
  display: flex;
  justify-content: center;
`;

const GroupBoxStyled2 = styled(GroupBox)`
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 10px;
  width: 225px;

  display: flex;
  justify-content: center;
`;

const TokenDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const TokenNameWrapper = styled.div`
  display: flex;
  margin-top: 20px;

  justify-content: center;
  align-items: center;

  p {
    font-size: 1rem;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  flex-direction: column;
`;

const ProgressWrapper = styled.div`
  width: 100%;
`;

const LargeButton = styled(Button)`
  font-size: 2rem;
  padding: 1rem 1rem;
  width: 250px;
  height: 70px;
`;

// Helper Functions
const formatPrizeAmount = (amount) => {
  const num = parseFloat(amount);
  return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
};

const calculateRemainingTime = (endTime) => {
  const now = new Date().getTime();
  const distance = new Date(endTime).getTime() - now;

  if (distance < 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

const truncateAddress = (address) =>
  `${address.slice(0, 5)}...${address.slice(-5)}`;

// Helper function to format ticket price appropriately
const formatTicketPrice = (price) => {
  const num = parseFloat(price);
  return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
};

// Main Component
const CompetitionCreator = ({
  competition: initialCompetition,
  updateCompetitions,
}) => {
  const [competition, setCompetition] = useState(initialCompetition);
  const [remainingTime, setRemainingTime] = useState(
    calculateRemainingTime(competition.end_time)
  );
  const [copied, setCopied] = useState(false);
  const { publicKey } = useWallet();
  const [canClaim, setCanClaim] = useState(false);
  const [feePercentages, setFeePercentages] = useState(null);
  const [error, setError] = useState(null);

  // Fetch fee percentages from backend
  useEffect(() => {
    const fetchFeePercentages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/fee-percentages`);
        setFeePercentages(response.data);
      } catch (error) {
        console.error("Error fetching fee percentages:", error);
        setError("Failed to fetch fee percentages.");
      }
    };
    fetchFeePercentages();
  }, []);

  const minimumPool = competition.winners_count * competition.ticket_price * 2;
  const currentAmount = formatPrizeAmount(competition.prize_pool || 0);
  const progressPercentage = Math.min(
    ((currentAmount / minimumPool) * 100).toFixed(1),
    100
  );

  const creatorPendingFee = parseFloat(competition.creator_pending_fee) || 0;

  // Update remaining time every second
  useEffect(() => {
    const interval = setInterval(() => {
      const newRemainingTime = calculateRemainingTime(competition.end_time);
      setRemainingTime(newRemainingTime);

      // Check if the timer has reached zero and the competition hasn't ended yet
      if (
        newRemainingTime.days <= 0 &&
        newRemainingTime.hours <= 0 &&
        newRemainingTime.minutes <= 0 &&
        newRemainingTime.seconds <= 0 &&
        competition.status !== "completed" &&
        competition.status !== "failed"
      ) {
        endCompetition();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [competition.end_time, competition.status]);

  // Function to end the competition
  const endCompetition = async () => {
    try {
      await axios.post(`${API_BASE_URL}/competitions/${competition.id}/end`);
      // Fetch the updated competition data
      const response = await axios.get(
        `${API_BASE_URL}/competitions/${competition.id}`
      );
      setCompetition(response.data);
    } catch (error) {
      console.error("Error ending competition:", error);
    }
  };

  // Reset the copied state after 2 seconds
  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  // Check if the creator can claim the fee
  useEffect(() => {
    const checkCanClaim = () => {
      if (!publicKey || publicKey.toBase58() !== competition.creator_id) {
        setCanClaim(false);
        return;
      }

      // Only allow claiming if the competition has ended successfully
      if (competition.status !== "completed") {
        setCanClaim(false);
        return;
      }

      if (creatorPendingFee > 0) {
        setCanClaim(true);
      } else {
        setCanClaim(false);
      }
    };

    checkCanClaim();
  }, [publicKey, competition, creatorPendingFee]);

  // Handle claim button click
  const handleClaimClick = async (e) => {
    e.stopPropagation();

    if (!publicKey) {
      alert("Please connect your wallet to claim the fee.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/competitions/${competition.id}/claim`,
        {
          user_id: publicKey.toBase58(),
        }
      );

      if (response.status === 200) {
        alert("Creator fee claimed successfully!");

        // Fetch the updated competition data
        const updatedCompetition = await axios.get(
          `${API_BASE_URL}/competitions/${competition.id}`
        );

        setCompetition(updatedCompetition.data);

        // Call updateCompetitions to inform the parent component about the change
        if (updateCompetitions) {
          updateCompetitions(updatedCompetition.data);
        }
      }
    } catch (error) {
      console.error("Error claiming creator fee:", error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Error: ${error.response.data.error}`);
      } else {
        alert("Error claiming creator fee. Please try again.");
      }
    }
  };

  // Format remaining time into a string
  const formatTime = (time) => {
    return `${time.days.toString().padStart(2, "0")}${time.hours
      .toString()
      .padStart(2, "0")}${time.minutes
      .toString()
      .padStart(2, "0")}${time.seconds.toString().padStart(2, "0")}`;
  };

  const timeString = formatTime(remainingTime);
  const poolPercentage = competition.pool_percentage;
  const tokenBuyPercentage = competition.token_buy_percentage;
  const protocolFeePercentage = feePercentages
    ? feePercentages.TOTAL_FEES_PERCENTAGE
    : "Loading...";

  // Function to determine the button label and disabled state
  const getClaimButtonProps = () => {
    if (!competition.status) {
      return { text: "Unknown", disabled: true };
    }

    const status = competition.status.toLowerCase();
    const currentTime = new Date().getTime();
    const competitionEndTime = new Date(competition.end_time).getTime();
    const isLive = currentTime < competitionEndTime;

    if (status === "failed") {
      return { text: "Failed", disabled: true };
    }

    if (isLive) {
      return { text: "On Going", disabled: true };
    }

    // If competition has ended
    if (status === "completed") {
      if (canClaim) {
        return { text: "Claim", disabled: false };
      }
      return { text: "Claimed", disabled: true };
    }

    // Fallback for any other status
    return { text: "Unknown", disabled: true };
  };

  const { text: claimButtonText, disabled: isButtonDisabled } =
    getClaimButtonProps();

  return (
    <CompetitionItemWrapper>
      <CompetitionWindow
        onClick={() => {
          // Navigate to competition page if needed
        }}
      >
        <WindowHeaderStyled>
          {competition.token_symbol}_{tokenBuyPercentage}_{poolPercentage}_
          {protocolFeePercentage}.exe
          <CountdownTimer>
            <StyledFrame>
              <Counter value={timeString} size="sm" />
            </StyledFrame>
          </CountdownTimer>
        </WindowHeaderStyled>
        <TokenNameWrapper>
          <p>{competition.token_name}</p>
        </TokenNameWrapper>

        <WindowContentStyled>
          <div>
            <CompetitionContent>
              <TokenDetails>
                <TokenAvatar>
                  <img
                    src={competition.token_icon}
                    alt={competition.token_symbol}
                  />
                </TokenAvatar>
                <p>
                  <ContractAddress>
                    {truncateAddress(competition.token)}
                    <CopyToClipboard
                      text={competition.token}
                      onCopy={() => setCopied(true)}
                    >
                      {/* Updated onClick handler to stop propagation */}
                      <FaCopy
                        className="copy-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCopied(true);
                        }}
                      />
                    </CopyToClipboard>
                  </ContractAddress>
                </p>
              </TokenDetails>
              <Wrapper>
                <CompetitionDetails2>
                  <GroupBoxStyled2 label="Jackpot">
                    <p>{currentAmount} SOL</p>
                  </GroupBoxStyled2>
                </CompetitionDetails2>
                <CompetitionDetails>
                  <GroupBoxStyled label="Ticket Price">
                    <p>{formatTicketPrice(competition.ticket_price)} SOL</p>
                  </GroupBoxStyled>
                  <GroupBoxStyled label="Winners">
                    <p>{competition.winners_count}</p>
                  </GroupBoxStyled>
                </CompetitionDetails>
              </Wrapper>
            </CompetitionContent>
          </div>
          <WindowContentStyled2>
            <CompetitionDetails2>
              <GroupBoxStyled2 label="Creator's Fee">
                <p>{formatPrizeAmount(creatorPendingFee)} SOL</p>
              </GroupBoxStyled2>
            </CompetitionDetails2>

            <LargeButton onClick={handleClaimClick} disabled={isButtonDisabled}>
              {claimButtonText}
            </LargeButton>
          </WindowContentStyled2>
          <ProgressWrapper>
            <ProgressBar value={progressPercentage} />
          </ProgressWrapper>
        </WindowContentStyled>
      </CompetitionWindow>
    </CompetitionItemWrapper>
  );
};

export default CompetitionCreator;
