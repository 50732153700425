import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import SortButtons from "../components/SortButtons";
import axios from "axios";
import styled from "styled-components";
import CompetitionItem from "../components/Competition";
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import io from "socket.io-client";
import { Checkbox } from "react95";
import GlobalJackpotDisplay from "../components/GlobalJackpotDisplay";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Wrapper for the homepage content
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
`;

// Main content styling
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 1920px;
  min-width: 320px;
  gap: 20px;

  @media (min-width: 768px) {
    width: 80%;
  }

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

// Competition list styling as a grid container
const CompetitionList = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1fr;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 1000px;
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1920px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  font-weight: normal; /* Ensure font weight stays consistent */
  height: 20px; /* Set a fixed height if necessary */
  /* Prevent margin or padding changes */
  margin: 0;
  padding: 0;
  color: white;

  /* Adjust the label style */
  & > span {
    font-weight: normal; /* Ensure label font weight is normal */
    line-height: 1; /* Adjust line height if needed */
  }

  /* Adjust the checkbox input style */
  & > input {
    margin: 0; /* Prevent margin shifts */
  }
`;

// TiltShineCard component for the tilt effect
const PERSPECTIVE = "1500px";
const ROTATION_RANGE = 15;
const HALF_ROTATION_RANGE = ROTATION_RANGE / 2;

const TiltShineCard = ({ children }) => {
  const ref = useRef(null);
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const xSpring = useSpring(x);
  const ySpring = useSpring(y);
  const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

  const sheenOpacity = useTransform(
    ySpring,
    [-HALF_ROTATION_RANGE, 0, HALF_ROTATION_RANGE],
    [0.5, 0, 0.5]
  );

  const handleMouseMove = (e) => {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();
    const width = rect.width;
    const height = rect.height;
    const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
    const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;
    const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
    const rY = mouseX / width - HALF_ROTATION_RANGE;

    x.set(rX);
    y.set(rY);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <div style={{ perspective: PERSPECTIVE }} className="relative">
      <motion.div
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          transform,
          backgroundSize: "cover",
        }}
        className="relative aspect-[9/13] w-80 overflow-hidden bg-zinc-950 shadow-2xl shadow-zinc-950"
      >
        <motion.div
          style={{ opacity: sheenOpacity }}
          className="absolute inset-0 bg-gradient-to-br from-zinc-300/50 via-zinc-300 to-zinc-300/50"
        />
        {children}
      </motion.div>
    </div>
  );
};

// New CompetitionCard component with glow animation
const CompetitionCardWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  transform-origin: center;
  transform: translateZ(0); /* Force GPU acceleration */
`;

const generateRandomColor = () => {
  const randomColorValue = () => Math.floor(Math.random() * 256);
  return `rgba(${randomColorValue()}, ${randomColorValue()}, ${randomColorValue()}, 0.9)`;
};

const CompetitionCard = memo(({ competition, onClick, glow }) => {
  const colorsRef = useRef({
    color1: generateRandomColor(),
    color2: generateRandomColor(),
    color3: generateRandomColor(),
    color4: generateRandomColor(),
  });
  const colors = colorsRef.current;

  const glowVariants = {
    glowing: {
      filter: [
        `drop-shadow(0 0 10px ${colors.color1})`,
        `drop-shadow(0 0 15px ${colors.color2})`,
        `drop-shadow(0 0 10px ${colors.color1})`,
      ],
      transition: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  return (
    <CompetitionCardWrapper
      onClick={onClick}
      variants={glowVariants}
      initial="initial"
      animate={glow ? "glowing" : "initial"}
    >
      <CompetitionItem competition={competition} />
    </CompetitionCardWrapper>
  );
});

const HomePage = () => {
  const [competitions, setCompetitions] = useState([]);
  const [globalJackpot, setGlobalJackpot] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    type: "hot",
    direction: "desc",
  });
  const [showFinishedCompetitions, setShowFinishedCompetitions] =
    useState(false);

  // Add the state variable for live updates
  const [liveUpdatesEnabled, setLiveUpdatesEnabled] = useState(true);

  const navigate = useNavigate();

  // Use a ref to store the socket instance
  const socketRef = useRef(null);

  // First useEffect: Fetch initial data
  useEffect(() => {
    // Fetch initial data
    const fetchCompetitions = async () => {
      try {
        // Updated to use API_BASE_URL
        const response = await axios.get(`${API_BASE_URL}/competitions`);
        setCompetitions(response.data);
      } catch (error) {
        console.error("Error fetching competitions:", error);
      }
    };

    const fetchGlobalJackpot = async () => {
      try {
        // Updated to use API_BASE_URL
        const response = await axios.get(`${API_BASE_URL}/api/global-jackpot`);
        setGlobalJackpot(response.data.totalJackpot);
      } catch (error) {
        console.error("Error fetching global jackpot:", error);
      }
    };

    fetchCompetitions();
    fetchGlobalJackpot();
  }, []); // Empty dependency array to run only once on mount

  // Second useEffect: Manage socket connection and event listeners
  useEffect(() => {
    if (liveUpdatesEnabled) {
      if (!socketRef.current) {
        // Initialize the socket connection using API_BASE_URL
        socketRef.current = io(API_BASE_URL);
      } else {
        // Reconnect if previously disconnected
        socketRef.current.connect();
      }

      const socket = socketRef.current;

      // Listen for events
      socket.on("newCompetition", handleNewCompetition);
      socket.on("globalJackpotUpdate", handleGlobalJackpotUpdate);
      socket.on("competitionUpdated", handleCompetitionUpdated);

      // Listen for connection and disconnection events
      socket.on("connect", () => {
        console.log("Connected to WebSocket server:", socket.id);
      });

      socket.on("disconnect", () => {
        console.log("Disconnected from WebSocket server");
      });

      // Cleanup event listeners when live updates are disabled or component unmounts
      return () => {
        socket.off("newCompetition", handleNewCompetition);
        socket.off("globalJackpotUpdate", handleGlobalJackpotUpdate);
        socket.off("competitionUpdated", handleCompetitionUpdated);
        socket.off("connect");
        socket.off("disconnect");
      };
    } else {
      // If live updates are disabled, disconnect the socket
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    }
    // Cleanup when the component unmounts
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [liveUpdatesEnabled]);

  // Event handlers
  const handleNewCompetition = (competition) => {
    console.log("New competition received:", competition);
    setCompetitions((prevCompetitions) => [...prevCompetitions, competition]);
  };

  const handleGlobalJackpotUpdate = (data) => {
    console.log("Global jackpot updated:", data.totalJackpot);
    setGlobalJackpot(data.totalJackpot);
  };

  const handleCompetitionUpdated = (updatedCompetition) => {
    console.log("Competition updated:", updatedCompetition);

    // Update the competitions state by merging updated fields
    setCompetitions((prevCompetitions) =>
      prevCompetitions.map((comp) =>
        comp.id === updatedCompetition.id
          ? { ...comp, ...updatedCompetition }
          : comp
      )
    );
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleSort = (type, direction) => {
    setSortConfig({ type, direction });
  };

  const filteredCompetitions = useMemo(() => {
    let comps = competitions;

    // Apply search term filter
    if (searchTerm) {
      comps = comps.filter((comp) =>
        comp.token_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply status filter
    if (!showFinishedCompetitions) {
      comps = comps.filter(
        (comp) => comp.status !== "completed" && comp.status !== "failed"
      );
    }

    // Apply sorting
    comps = comps.sort((a, b) => {
      let comparison = 0;
      const { type, direction } = sortConfig;
      switch (type) {
        case "hot":
          {
            const aLastPurchaseTime = new Date(
              a.last_purchase_time || 0
            ).getTime();
            const bLastPurchaseTime = new Date(
              b.last_purchase_time || 0
            ).getTime();
            comparison = aLastPurchaseTime - bLastPurchaseTime;
          }
          break;

        case "creation":
          comparison = new Date(a.createdAt) - new Date(b.createdAt);
          break;
        case "jackpot":
          comparison = (a.prize_pool || 0) - (b.prize_pool || 0);
          break;
        case "time":
          {
            const aTimeRemaining = new Date(a.end_time) - new Date();
            const bTimeRemaining = new Date(b.end_time) - new Date();
            comparison = bTimeRemaining - aTimeRemaining;
          }
          break;
        default:
          break;
      }
      return direction === "asc" ? comparison : -comparison;
    });

    return comps;
  }, [competitions, searchTerm, showFinishedCompetitions, sortConfig]);

  const handleCompetitionClick = (id) => {
    navigate(`/competition/${id}`);
  };

  const handleJackpotClick = () => {
    navigate("/global-jackpot"); // Redirect to GlobalJackpotPage
  };

  const getProgressPercentage = (competition) => {
    const minimumPool = competition.minimum_pool || 1; // Avoid division by 0
    const currentAmount = competition.prize_pool || 0;
    const progressPercentage = Math.min(
      ((currentAmount / minimumPool) * 100).toFixed(1),
      100
    );

    return progressPercentage;
  };

  return (
    <Wrapper>
      <MainContent>
        {/* Display global jackpot total */}

        <GlobalJackpotDisplay
          jackpotAmount={globalJackpot}
          onClick={handleJackpotClick}
        />

        <SearchBar onSearch={handleSearch} />
        <SortButtons onSort={handleSort} />
        {/* Include Finished Competitions Checkbox */}
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <StyledCheckbox
            label="Show Finished?"
            checked={showFinishedCompetitions}
            onChange={() =>
              setShowFinishedCompetitions(!showFinishedCompetitions)
            }
          />
          <StyledCheckbox
            label="Live Updates"
            checked={liveUpdatesEnabled}
            onChange={() => setLiveUpdatesEnabled(!liveUpdatesEnabled)}
          />
        </div>

        <CompetitionList>
          {filteredCompetitions.map((competition) => {
            const progressPercentage = getProgressPercentage(competition);

            return (
              <TiltShineCard key={competition.id}>
                <CompetitionCard
                  competition={competition}
                  onClick={() => handleCompetitionClick(competition.id)}
                  glow={progressPercentage >= 100} // Apply glow when progress is 100%
                />
              </TiltShineCard>
            );
          })}
        </CompetitionList>
      </MainContent>
    </Wrapper>
  );
};

export default HomePage;
