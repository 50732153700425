import React, { useState } from "react";
import { Button } from "react95";
import styled from "styled-components";

// Styled Button to make all buttons responsive
const StyledButton = styled(Button)`
  width: 100px; // Default width for buttons
  margin: 1px; // Add some margin for spacing
  display: flex; // Use flex to allow content to adjust
  justify-content: center;
  align-items: center;
  white-space: nowrap; // Prevent wrapping

  @media (max-width: 768px) {
    width: 85px; // Reduced width for smaller screens
  }

  max-font-size: 14px; // Limit maximum font size for consistency
  min-font-size: 10px; // Ensure text remains legible
`;

// Styled container for buttons to align them in a row
const ButtonContainer = styled.div`
  display: flex; // Use flexbox for layout
  flex-direction: row; // Align items in a row
`;

const SortButtons = ({ onSort }) => {
  const [activeSort, setActiveSort] = useState({
    type: "hot",
    direction: "desc",
  });

  const defaultDirections = {
    hot: "desc",
    creation: "desc",
    jackpot: "desc",
    time: "desc", // Set "Time" to default to "asc"
  };

  const handleClick = (type) => {
    setActiveSort((prevState) => {
      if (prevState.type === type) {
        const newDirection = prevState.direction === "asc" ? "desc" : "asc";
        onSort(type, newDirection);
        return { type, direction: newDirection };
      } else {
        const defaultDirection = defaultDirections[type] || "desc";
        onSort(type, defaultDirection);
        return { type, direction: defaultDirection };
      }
    });
  };

  const renderArrow = (type) => {
    if (activeSort.type !== type) return null;
    return activeSort.direction === "asc" ? "▲" : "▼";
  };

  return (
    <ButtonContainer>
      <StyledButton
        active={activeSort.type === "hot"}
        onClick={() => handleClick("hot")}
      >
        Hot {renderArrow("hot")}
      </StyledButton>
      <StyledButton
        active={activeSort.type === "creation"}
        onClick={() => handleClick("creation")}
      >
        Creation {renderArrow("creation")}
      </StyledButton>
      <StyledButton
        active={activeSort.type === "jackpot"}
        onClick={() => handleClick("jackpot")}
      >
        Jackpot {renderArrow("jackpot")}
      </StyledButton>
      <StyledButton
        active={activeSort.type === "time"}
        onClick={() => handleClick("time")}
      >
        Time {renderArrow("time")}
      </StyledButton>
    </ButtonContainer>
  );
};

export default SortButtons;
