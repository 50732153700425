import React, { createContext, useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import axios from "axios";

// Create the AdminContext
export const AdminContext = createContext();

// AdminProvider component to provide admin state
export const AdminProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false); // Admin state
  const { publicKey } = useWallet(); // Get wallet public key

  // Get API base URL from environment variable
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (publicKey) {
        try {
          const walletAddress = publicKey.toBase58();
          const response = await axios.get(`${API_BASE_URL}/api/check-admin`, {
            params: { user_id: walletAddress },
          });
          setIsAdmin(response.data.isAdmin); // Set admin status based on response
        } catch (error) {
          console.error("Error checking admin status:", error);
        }
      }
    };

    checkAdminStatus();
  }, [publicKey, API_BASE_URL]);

  return (
    <AdminContext.Provider value={{ isAdmin }}>
      {children}
    </AdminContext.Provider>
  );
};
