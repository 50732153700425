import { createGlobalStyle } from "styled-components";
import { styleReset } from "react95";

const GlobalStyles = createGlobalStyle`
  ${styleReset}


  html, body {
    font-family: 'ms_sans_serif';
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.desktopBackground};
    overflow: hidden; /* Hides scrollbar */
    overflow-y: scroll; /* Allows scrolling without visible scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
  }

  body::-webkit-scrollbar { 
    display: none;  /* Chrome, Safari, and Opera */
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }

  .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    text-align: center;
    background: none;
  }

  .carousel img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .custom-table {
    display: block;
    width: 100%;
    max-height: 600px;  // Adjust based on your UI requirements
    overflow-y: auto;   // Enables scrolling on the y-axis
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer 10+ */

    /* For Webkit browsers like Chrome, Safari, and Opera */
    &::-webkit-scrollbar { 
      display: none;
    }
  }

.shape {
  position: absolute;
  transition: transform 0.1s linear; /* Smooth transitions */
  will-change: transform; /* Optimization hint */
}



`;

export default GlobalStyles;
