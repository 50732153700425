import React, { useState } from "react";
import { TextInput } from "react95";
import styled from "styled-components";

// Styled TextInput for adjusting size
const StyledTextInput = styled(TextInput)`
  width: 400px;
  max-width: 400px;

  @media (max-width: 480px) {
    width: 100%; // Increase width on small screens (e.g., phones)
  }
`;

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <StyledTextInput
      placeholder="Search by name..."
      value={searchTerm}
      onChange={handleSearch}
    />
  );
};

export default SearchBar;
