import React, { useContext, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import GlobalStyles from "./GlobalStyles";
import Homepage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import CompetitionPage from "./pages/CompetitionPage";
import CompetitionDetailsPage from "./pages/CompetitionDetailsPage";
import AppBarComponent from "./components/AppBarComponent";
import GlobalJackpotDisplay from "./components/GlobalJackpotDisplay";
import GlobalJackpotPage from "./pages/GlobalJackpotPage";
import FloatingShapes from "./components/FloatingShapes";
import DashboardPage from "./pages/Dashboardpage";
import styled from "styled-components";
import {
  WalletProvider,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { ThemeContext } from "./context/ThemeContext";
import { AdminProvider } from "./context/AdminContext";
import { AnimatePresence, motion } from "framer-motion";
import "@solana/wallet-adapter-react-ui/styles.css";

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  flex: 1;
  overflow-y: auto;
  margin-top: 5%;

  @media (max-width: 768px) {
    margin-top: 15%;
  }
`;

// Suppress warnings code...
const suppressWarnings = (msg) => {
  const message = typeof msg === "string" ? msg : String(msg);

  return (
    message.includes("Module not found: Error: Can't resolve") ||
    message.includes("BREAKING CHANGE: webpack < 5 used to include polyfills")
  );
};

const originalWarn = console.warn;
console.warn = (msg, ...args) => {
  if (!suppressWarnings(msg)) {
    originalWarn(msg, ...args);
  }
};

const originalError = console.error;
console.error = (msg, ...args) => {
  if (!suppressWarnings(msg)) {
    originalError(msg, ...args);
  }
};

// src/App.js

const AppContent = () => {
  const location = useLocation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <AppBarComponent />
      <div
        style={{
          flex: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <FloatingShapes />
        <ContentWrapper>
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
              {/* Your routes */}
              <Route
                path="/"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <Homepage />
                  </motion.div>
                }
              />
              <Route
                path="/create-competition"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <CompetitionPage />
                  </motion.div>
                }
              />
              <Route
                path="/competition/:id"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <CompetitionDetailsPage />
                  </motion.div>
                }
              />
              <Route
                path="/profile/:wallet_address"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <ProfilePage />
                  </motion.div>
                }
              />
              <Route
                path="/global-jackpot"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <GlobalJackpotPage />
                  </motion.div>
                }
              />
              <Route
                path="/admin-dashboard"
                element={
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <DashboardPage />
                  </motion.div>
                }
              />
            </Routes>
          </AnimatePresence>
        </ContentWrapper>
      </div>
    </div>
  );
};

const App = () => {
  const network = clusterApiUrl("devnet");
  const wallets = [new PhantomWalletAdapter(), new SolflareWalletAdapter()];

  const { theme } = useContext(ThemeContext);

  return (
    <ConnectionProvider endpoint={network}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <AdminProvider>
            <StyledThemeProvider theme={theme}>
              <GlobalStyles />
              <Router>
                <AppContent />
              </Router>
            </StyledThemeProvider>
          </AdminProvider>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
