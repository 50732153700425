import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
  Avatar,
  Window,
  WindowContent,
  WindowHeader,
  Button,
  GroupBox,
  Radio,
} from "react95";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ThemeContext } from "../context/ThemeContext";
import axios from "axios";

// Predefined GIFs for avatars
const AVATARS = [
  "/avatars/avatar1.gif",
  "/avatars/avatar2.gif",
  "/avatars/avatar3.gif",
  "/avatars/avatar4.gif",
  "/avatars/avatar5.gif",
  "/avatars/avatar6.gif",
  "/avatars/avatar7.gif",
  "/avatars/avatar8.gif",
  "/avatars/avatar9.gif",
  "/avatars/avatar10.gif",
  "/avatars/avatar11.gif",
  "/avatars/avatar12.gif",
  "/avatars/avatar13.gif",
  "/avatars/avatar14.gif",
  "/avatars/avatar15.gif",
  "/avatars/avatar16.gif",
  "/avatars/avatar17.gif",
  "/avatars/avatar18.gif",
  "/avatars/avatar19.gif",
  "/avatars/avatar20.gif",
];

// Available themes
const THEMES = [
  { value: "original", label: "Original" },
  { value: "matrix", label: "Matrix" },
  { value: "pamelaAnderson", label: "Pamela Anderson" },
  { value: "blue", label: "Blue" },
  { value: "olive", label: "Olive" },
  { value: "tokyoDark", label: "Tokyo Dark" },
  { value: "polarized", label: "Polarized" },
  { value: "tooSexy", label: "Too Sexy" },
  { value: "modernDark", label: "Modern Dark" },
  { value: "denim", label: "Denim" },
  { value: "azureOrange", label: "Azure Orange" },
  { value: "theSixtiesUSA", label: "The Sixties USA" },
  { value: "hotChocolate", label: "Hot Chocolate" },
  { value: "violetDark", label: "Violet Dark" },
];

// Truncate function to shorten wallet address
const truncateAddress = (address) =>
  `${address.slice(0, 5)}...${address.slice(-5)}`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

const WalletAddress = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  flex-grow: 1;
`;

const AvatarPopup = styled(Window)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 300px;
  padding: 10px;
  box-sizing: border-box;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const AvatarSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
`;

const StyledAvatar = styled(Avatar)`
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WindowHeaderStyled = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 2%;
`;

const GroupBoxStyled = styled(GroupBox)`
  width: 50%;
  margin-top: 10px;
  height: 100%;

  @media (max-width: 720px) {
    width: 95%;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  > label {
    width: 50%;
    box-sizing: border-box;
  }

  @media (max-width: 720px) {
    > label {
      width: 50%;
      box-sizing: border-box;
    }
  }
`;

const ProfileAvatarSelector = ({
  walletAddress,
  initialAvatar,
  initialTheme,
  updateProfile,
}) => {
  const [selectedAvatar, setSelectedAvatar] = useState(
    initialAvatar || AVATARS[0]
  );
  const [selectedTheme, setSelectedTheme] = useState(
    initialTheme || THEMES[0].value
  );
  const [copied, setCopied] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { changeTheme } = useContext(ThemeContext);

  // Fetch API base URL from environment variable
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

  const handleAvatarClick = () => {
    setShowPopup(true);
  };

  const updateProfileInBackend = async (data) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/users/avatar`, {
        wallet_address: walletAddress,
        ...data,
      });
      if (response.status === 200) {
        console.log("Profile updated successfully!");
        updateProfile(data);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile");
    }
  };

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
    updateProfileInBackend({ avatar_url: avatar });
    setShowPopup(false);
  };

  const handleThemeChange = (e) => {
    const newTheme = e.target.value;
    setSelectedTheme(newTheme);
    updateProfileInBackend({ theme: newTheme });
    changeTheme(newTheme);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    if (initialAvatar) {
      setSelectedAvatar(initialAvatar);
    }
    if (initialTheme) {
      setSelectedTheme(initialTheme);
      changeTheme(initialTheme);
    }
  }, [initialAvatar, initialTheme, changeTheme]);

  return (
    <AvatarContainer>
      <br />
      <StyledAvatar square size={80} onClick={handleAvatarClick}>
        <img src={selectedAvatar} alt="Selected Avatar" />
      </StyledAvatar>
      <WalletAddress title={walletAddress}>
        {truncateAddress(walletAddress)}
        <CopyToClipboard text={walletAddress} onCopy={handleCopy}>
          <FaCopy className="copy-icon" style={{ cursor: "pointer" }} />
        </CopyToClipboard>
        {copied && <span>Copied!</span>}
      </WalletAddress>
      <br />

      <GroupBoxStyled label="Choose a Theme">
        <RadioGroup>
          {THEMES.map((theme) => (
            <Radio
              key={theme.value}
              checked={selectedTheme === theme.value}
              onChange={handleThemeChange}
              value={theme.value}
              label={theme.label}
              name="themes"
            />
          ))}
        </RadioGroup>
      </GroupBoxStyled>

      {showPopup && (
        <>
          <Overlay onClick={() => setShowPopup(false)} />
          <AvatarPopup>
            <WindowHeaderStyled>
              <span>Avatar.eXe</span>
              <Button
                onClick={() => setShowPopup(false)}
                style={{ marginLeft: "auto", padding: "0 10px" }}
              >
                X
              </Button>
            </WindowHeaderStyled>
            <WindowContent>
              <AvatarSelection>
                {AVATARS.map((avatar, index) => (
                  <StyledAvatar
                    square
                    key={index}
                    size={50}
                    onClick={() => handleAvatarSelect(avatar)}
                  >
                    <img src={avatar} alt={`Avatar ${index + 1}`} />
                  </StyledAvatar>
                ))}
              </AvatarSelection>
            </WindowContent>
          </AvatarPopup>
        </>
      )}
    </AvatarContainer>
  );
};

export default ProfileAvatarSelector;
