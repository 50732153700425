import React, { useState, useEffect, useRef, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  MenuList,
  MenuListItem,
  Separator,
} from "react95";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import axios from "axios";
import "@solana/wallet-adapter-react-ui/styles.css";
import { ThemeContext } from "../context/ThemeContext";
import logo from "../assets/svgs/logo.svg";
import { motion } from "framer-motion";
import { AdminContext } from "../context/AdminContext"; // Import AdminContext

const StyledAppBar = styled(AppBar)`
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 5000;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  max-width: 1920px;
  margin: 0 auto;
`;

const WalletButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledWalletButton = styled(Button)`
  width: 125px;
  text-align: center;
  white-space: nowrap;
`;

const DropdownMenu = styled(MenuList)`
  position: absolute;
  right: 0;
  top: 100%;
  width: 150px;
  z-index: 1000;
`;

const CreatePool = styled(Button)`
  width: 125px;
`;

const StyledImage = styled.img`
  width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 85px;
  }
`;

const AppBarComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAdmin } = useContext(AdminContext); // Access isAdmin from context
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const { setVisible } = useWalletModal();
  const { publicKey, disconnect } = useWallet();
  const { changeTheme } = useContext(ThemeContext);

  // Use environment variable for API base URL
  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !event.target.closest(".wallet-button")
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const createOrFetchUserProfile = async (walletAddress) => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/users`, {
          wallet_address: walletAddress,
        });
        const userData = response.data;

        // Apply the user's theme or fallback to the default
        if (userData && userData.theme) {
          changeTheme(userData.theme);
        } else {
          changeTheme("original");
        }
      } catch (error) {
        console.error("Error creating or fetching user profile:", error);
      }
    };

    if (publicKey) {
      createOrFetchUserProfile(publicKey.toBase58());
    } else {
      changeTheme("original");
    }
  }, [publicKey, API_BASE_URL]);

  const handleConnectWallet = () => {
    setVisible(true);
  };

  const handleDisconnectWallet = () => {
    disconnect().then(() => {
      changeTheme("original");
      setMenuOpen(false);
      navigate("/"); // Redirect to home after disconnecting
    });
  };

  const truncatedAddress = publicKey
    ? `${publicKey.toBase58().slice(0, 4)}...${publicKey.toBase58().slice(-4)}`
    : "";

  const handleCreatePool = () => {
    navigate("/create-competition");
  };

  const handleAccountClick = () => {
    navigate(`/profile/${publicKey?.toBase58()}`);
    setMenuOpen(false);
  };

  const handleAdminDashboardClick = () => {
    navigate("/admin-dashboard"); // Navigate to the admin dashboard
    setMenuOpen(false);
  };

  const handleHomeClick = () => {
    navigate(`/`);
    setMenuOpen(false);
  };

  return (
    <StyledAppBar>
      <StyledToolbar>
        <CreatePool type="button" onClick={handleCreatePool}>
          Create Pool
        </CreatePool>
        <motion.div
          onClick={handleHomeClick}
          whileHover={{ scale: 1.2, rotateY: 360 }}
          whileTap={{ scale: 0.8 }}
          transition={{ type: "spring", stiffness: 300, damping: 20 }}
        >
          <StyledImage src={logo} alt="Logo" />
        </motion.div>
        <WalletButtonWrapper>
          <StyledWalletButton
            onClick={() =>
              publicKey ? setMenuOpen(!menuOpen) : handleConnectWallet()
            }
            className="wallet-button"
          >
            {publicKey ? truncatedAddress : "Connect"}
          </StyledWalletButton>
          {menuOpen && publicKey && (
            <DropdownMenu ref={menuRef}>
              <MenuListItem onClick={handleHomeClick}>
                <span role="img" aria-label="🏠">
                  🏠
                </span>
                Home
              </MenuListItem>
              <Separator />
              <MenuListItem onClick={handleAccountClick}>
                <span role="img" aria-label="👨‍💻">
                  👨‍💻
                </span>
                Account
              </MenuListItem>
              {isAdmin && (
                <>
                  <Separator />
                  <MenuListItem onClick={handleAdminDashboardClick}>
                    <span role="img" aria-label="🛠️">
                      🛠️
                    </span>
                    Admin
                  </MenuListItem>
                </>
              )}
              <Separator />
              <MenuListItem onClick={handleDisconnectWallet}>
                <span role="img" aria-label="🔙">
                  🔙
                </span>
                Disconnect
              </MenuListItem>
            </DropdownMenu>
          )}
        </WalletButtonWrapper>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default AppBarComponent;
