// src/pages/CompetitionPage.js

import React, { useState, useEffect } from "react";
import {
  Button,
  TextInput,
  Checkbox,
  NumberInput,
  Select,
  Window,
  WindowContent,
  WindowHeader,
  Hourglass,
  Slider,
} from "react95";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useWallet } from "@solana/wallet-adapter-react";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

// Style for the form
const CompetitionForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

// Wrapper for the modal content
const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const ResponsiveWindow = styled(Window)`
  width: 100%;
  max-width: 600px;
  pointer-events: auto;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
`;

const TokenInfo = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 32px;
    height: 32px;
  }
`;

const InputWithButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SearchButton = styled(Button)`
  width: 90px;
`;

const SliderWrapper = styled.div`
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
`;

const Label = styled.label`
  font-weight: bold;
`;

const SliderDetails = styled.div`
  margin-top: 10px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
`;

const CompetitionPage = () => {
  const [formData, setFormData] = useState({
    contractAddress: "",
    ticketPrice: 0,
    numberOfWinners: 1,
    seedAmount: 0,
    seedEnabled: false,
    endDate: "",
    endTime: "",
    customAllocationEnabled: false,
    poolPercentage: 20,
    description: "",
  });

  const [selectedToken, setSelectedToken] = useState(null);
  const [feePercentages, setFeePercentages] = useState({
    totalFeesPercentage: 5, // Default value; update if necessary
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { publicKey } = useWallet(); // Get the public key directly

  useEffect(() => {
    console.log("Public Key state:", publicKey); // Debugging line
    if (!publicKey) {
      setError("Please connect your wallet first.");
    } else {
      setError(""); // Clear the error when the wallet is connected
    }
  }, [publicKey]);

  // Fetch fee percentages from the backend
  useEffect(() => {
    const fetchFeePercentages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/fee-percentages`);

        // Set the fee percentage state
        setFeePercentages({
          totalFeesPercentage: response.data.TOTAL_FEES_PERCENTAGE, // Ensure key matches backend response
        });

        // Log the totalFeesPercentage from the response
        console.log(
          "Backend totalFeesPercentage:",
          response.data.TOTAL_FEES_PERCENTAGE
        );
      } catch (error) {
        console.error("Error fetching fee percentages:", error);
        setError("Failed to fetch fee percentages.");
      }
    };
    fetchFeePercentages();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const fetchTokenInfo = async () => {
    setIsLoading(true);
    setError("");
    try {
      if (!formData.contractAddress) {
        setError("Please enter a valid contract address.");
        setIsLoading(false);
        return;
      }

      const response = await axios.get(`${API_BASE_URL}/api/token-info`, {
        params: { address: formData.contractAddress },
      });

      if (response.data) {
        setSelectedToken(response.data);
      } else {
        setError("Token not found. Please check the contract address.");
      }
    } catch (error) {
      console.error("Error fetching token metadata:", error);
      setError("Failed to fetch token information.");
    } finally {
      setIsLoading(false);
    }
  };

  const resetTokenInfo = () => {
    setFormData({ ...formData, contractAddress: "" });
    setSelectedToken(null);
    setError("");
  };

  const handleNumberChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      numberOfWinners: selectedOption.value,
    });
  };

  const handleSliderChange = (newValue) => {
    setFormData({
      ...formData,
      poolPercentage: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedToken) {
      setError("Please search for and select a valid token first.");
      return;
    }

    if (!publicKey) {
      setError("Wallet not connected. Please connect your wallet.");
      return;
    }

    const end_time = new Date(`${formData.endDate}T${formData.endTime}`);
    const now = new Date();
    const duration = Math.round((end_time - now) / 1000);

    const formattedTicketPrice = parseFloat(formData.ticketPrice.toFixed(2));
    const formattedSeedAmount = formData.seedEnabled
      ? parseFloat(formData.seedAmount.toFixed(9))
      : 0;

    // Calculate pool percentage based on whether custom allocation is enabled
    const poolPercentage = formData.customAllocationEnabled
      ? formData.poolPercentage
      : 20;

    // Calculate the token buy percentage
    const tokenBuyPercentage =
      100 - poolPercentage - feePercentages.totalFeesPercentage;

    // Log all the calculated values
    console.log("End time:", end_time.toISOString());
    console.log("Duration:", duration);
    console.log("Ticket Price:", formattedTicketPrice);
    console.log("Seed Amount:", formattedSeedAmount);
    console.log("Total Fees Percentage:", feePercentages.totalFeesPercentage);
    console.log("Pool Percentage:", poolPercentage);
    console.log("Token Buy Percentage:", tokenBuyPercentage);

    try {
      const response = await axios.post(`${API_BASE_URL}/competitions`, {
        creator_id: publicKey.toBase58(),
        ticket_price: formattedTicketPrice,
        winners_count: formData.numberOfWinners,
        duration,
        end_time: end_time.toISOString(),
        token: formData.contractAddress,
        poolPercentage, // Use the calculated pool percentage
        token_buy_percentage: tokenBuyPercentage,
        seed_amount: formattedSeedAmount,
        description: formData.description,
      });

      // Redirect to the competition's details page
      navigate(`/competition/${response.data.id}`, {
        state: { fromCreateCompetition: true },
      });
    } catch (error) {
      console.error("Error creating competition:", error);
      setError("Failed to create competition. Please try again.");
    }
  };

  const handleBackNavigation = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Wrapper>
      <ResponsiveWindow>
        <WindowHeader
          className="window-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>CreateCompetition.eXe</span>
          <Button
            onClick={handleBackNavigation}
            style={{ marginLeft: "auto", padding: "0 10px" }}
          >
            X
          </Button>
        </WindowHeader>
        <WindowContent>
          <CompetitionForm onSubmit={handleSubmit}>
            <label>Buying Competition</label>
            <InputWithButton>
              <TextInput
                placeholder="Enter Contract Address"
                name="contractAddress"
                value={formData.contractAddress}
                onChange={handleChange}
                fullWidth
              />
              <SearchButton
                type="button"
                onClick={selectedToken ? resetTokenInfo : fetchTokenInfo}
                disabled={isLoading || !formData.contractAddress}
              >
                {selectedToken ? (
                  "Reset"
                ) : isLoading ? (
                  <Hourglass size={16} />
                ) : (
                  "Search"
                )}
              </SearchButton>
            </InputWithButton>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {selectedToken && (
              <TokenInfo>
                <img src={selectedToken.icon} alt={selectedToken.symbol} />
                <div>
                  <strong>{selectedToken.name}</strong>
                  <p>${selectedToken.symbol}</p>
                </div>
              </TokenInfo>
            )}
            {/* Description Input */}
            <label>Description (max 250 characters)</label>
            <TextInput
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
              maxLength={250}
              fullWidth
            />

            <label>Ticket Price</label>
            <NumberInput
              defaultValue={formData.ticketPrice}
              step={0.1}
              min={0.1}
              onChange={(value) => {
                if (value >= 0.1) {
                  handleNumberChange("ticketPrice", value);
                }
              }}
              width="100%"
            />
            <label>Number of Winners</label>
            <Select
              options={Array.from({ length: 3 }, (_, i) => ({
                label: `${i + 1}`,
                value: i + 1,
              }))}
              defaultValue={formData.numberOfWinners}
              onChange={handleSelectChange}
              width="100%"
            />
            <label>End Date</label>
            <TextInput
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              fullWidth
            />
            <label>End Time</label>
            <TextInput
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
              fullWidth
            />
            <Checkbox
              label="Seed the competition?"
              name="seedEnabled"
              checked={formData.seedEnabled}
              onChange={handleChange}
            />
            {formData.seedEnabled && (
              <>
                <label>Seed Amount</label>
                <NumberInput
                  defaultValue={formData.seedAmount}
                  onChange={(value) => handleNumberChange("seedAmount", value)}
                  width="100%"
                />
              </>
            )}
            <Checkbox
              label="Customize Allocation?"
              name="customAllocationEnabled"
              checked={formData.customAllocationEnabled}
              onChange={handleChange}
            />
            {formData.customAllocationEnabled && (
              <SliderWrapper>
                <Label>Allocate Ticket Price:</Label>
                <Slider
                  size="100%"
                  min={5}
                  max={90}
                  value={formData.poolPercentage}
                  onChange={handleSliderChange}
                />
                <SliderDetails>
                  <span>
                    Buy Tokens:{" "}
                    {100 -
                      formData.poolPercentage -
                      feePercentages.totalFeesPercentage}
                    %
                  </span>
                  <span>Jackpot/Pool: {formData.poolPercentage}%</span>
                  <span>Protocol: {feePercentages.totalFeesPercentage}%</span>
                </SliderDetails>
              </SliderWrapper>
            )}
            <Button type="submit">Create Competition</Button>
          </CompetitionForm>
        </WindowContent>
      </ResponsiveWindow>
    </Wrapper>
  );
};

export default CompetitionPage;
