import React, { useEffect } from "react";
import Matter from "matter-js";

// Import your SVGs

import shape1 from "../assets/svgs/shape1.svg";
import shape2 from "../assets/svgs/shape2.svg";
import shape3 from "../assets/svgs/shape3.svg";
import shape4 from "../assets/svgs/shape4.svg";
import shape5 from "../assets/svgs/shape5.svg";

const FloatingShapes = () => {
  useEffect(() => {
    // Create Matter.js engine and world
    const engine = Matter.Engine.create();
    const world = engine.world;

    // Apply small negative gravity to push shapes upward slowly
    engine.world.gravity.y = -0.005; // Gravity pushing upward
    engine.world.gravity.x = 0; // No horizontal gravity

    // Setup renderer (the container for the shapes)
    const shapesContainer = document.createElement("div");
    shapesContainer.style.position = "absolute";
    shapesContainer.style.top = "0";
    shapesContainer.style.left = "0";
    shapesContainer.style.width = "100vw";
    shapesContainer.style.height = "100vh";
    shapesContainer.style.zIndex = "0"; // Background level
    shapesContainer.style.overflow = "hidden";
    document.body.appendChild(shapesContainer);

    // Array of SVGs to randomly choose from
    const svgArray = [shape1, shape2, shape3, shape4, shape5];

    // Array to hold all floating shape elements and their physics bodies
    const floatingShapes = [];

    // Function to create a new SVG shape with random mass, initial velocity, rotation, and color
    const createShape = (startX, startY) => {
      const size = Math.random() * 100 + 50; // Random size for the SVG

      // Generate random mass between 0.5 and 8 for more variation
      const mass = Math.random() * 7.5 + 0.5; // Random mass between 0.5 and 8

      // Randomly select an SVG from the array
      const randomSvg = svgArray[Math.floor(Math.random() * svgArray.length)];

      // Create an img element for the SVG
      const shape = document.createElement("img");
      shape.src = randomSvg;
      shape.style.width = `${size}px`;
      shape.style.height = `${size}px`;
      shape.style.position = "absolute";

      // Apply random rotation angle and rotation velocity
      const initialRotation = Math.random() * 360; // Random initial rotation (degrees)
      const rotationSpeed = (Math.random() - 0.5) * 0.5; // Random speed of rotation
      shape.style.transform = `translate(${startX}px, ${startY}px) rotate(${initialRotation}deg)`; // Apply initial position and rotation

      // Apply random color with CSS filter (using sepia and brightness for color overlay)
      const randomHue = Math.random() * 360; // Random hue rotation
      shape.style.filter = `sepia(1) saturate(10) brightness(0.6) hue-rotate(${randomHue}deg)`; // Apply random color filter

      shapesContainer.appendChild(shape);

      // Calculate air resistance based on mass (heavier shapes float slower)
      const frictionAir = mass * 0.001; // Lower air resistance for more dramatic bounces

      // Calculate the size of the rectangle body to be 60% of the SVG size
      const rectWidth = size * 0.9; // 60% of the SVG width
      const rectHeight = size * 0.9; // 60% of the SVG height

      // Use a rectangle body instead of a circle to match the shape's bounding box
      const body = Matter.Bodies.rectangle(
        startX || Math.random() * window.innerWidth, // Random horizontal position if not provided
        startY || window.innerHeight + size, // Start well below the screen if not provided
        rectWidth, // Width (60% of the SVG size)
        rectHeight, // Height (60% of the SVG size)
        {
          restitution: 1.5, // High bounciness
          friction: 0, // No friction to retain energy during collisions
          frictionAir: frictionAir, // Lower air resistance to retain velocity
          mass: mass, // Set the random mass for the body
        }
      );

      // Apply a random initial velocity for a unique floating trajectory
      const randomVelocityX = (Math.random() - 0.5) * 0.5; // Random horizontal velocity
      const randomVelocityY = (Math.random() - 0.5) * 0.5; // Random vertical velocity (slight drift)

      Matter.Body.setVelocity(body, {
        x: randomVelocityX, // Horizontal velocity
        y: randomVelocityY + -0.1, // Upward velocity + random drift
      });

      floatingShapes.push({ element: shape, body, rotationSpeed });
      Matter.World.add(world, body);

      // Remove the shape once it's off the top of the screen
      Matter.Events.on(engine, "beforeUpdate", () => {
        if (body.position.y < -size) {
          Matter.World.remove(world, body); // Remove the body
          if (shapesContainer.contains(shape)) {
            shapesContainer.removeChild(shape); // Remove the visual element safely
          }
        }
      });
    };

    // Create initial batch of shapes randomly placed around the screen
    const createInitialShapes = () => {
      for (let i = 0; i < 20; i++) {
        // Adjust count as needed
        createShape(
          Math.random() * window.innerWidth, // Random x position
          Math.random() * window.innerHeight // Random y position
        );
      }
    };

    // Create new shapes from the bottom every second
    const createNewShapesFromBottom = () => {
      const shapeInterval = setInterval(() => {
        createShape(
          Math.random() * window.innerWidth,
          window.innerHeight + 300
        ); // Start even further below the screen
      }, 2000); // New shape every second

      return shapeInterval;
    };

    // Start with the initial shapes
    createInitialShapes();

    // Start creating new shapes from the bottom
    const shapeInterval = createNewShapesFromBottom();

    // Animation loop using Matter.js
    const updateShapes = () => {
      Matter.Engine.update(engine);

      floatingShapes.forEach(({ element, body, rotationSpeed }) => {
        // Update position and apply rotation over time
        const currentRotation =
          parseFloat(element.style.transform.split("rotate(")[1]) || 0;
        element.style.transform = `translate(${body.position.x}px, ${
          body.position.y
        }px) rotate(${currentRotation + rotationSpeed}deg)`;
      });

      requestAnimationFrame(updateShapes);
    };
    updateShapes();

    // Clean up on unmount
    return () => {
      clearInterval(shapeInterval);
      if (document.body.contains(shapesContainer)) {
        document.body.removeChild(shapesContainer);
      }
      Matter.World.clear(world);
      Matter.Engine.clear(engine);
    };
  }, []);

  return null; // No visible UI from this component
};

export default FloatingShapes;
