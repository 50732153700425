// src/components/GlobalJackpotDisplay.js
import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { motion } from "framer-motion";

const GlobalJackpotDisplayWrapper = styled.div`
  font-size: 4rem;
  font-weight: bold;
  cursor: pointer;
  justify-content: center;
  display: flex;
  text-align: center;

  letter-spacing: 5px;
  background: linear-gradient(
    to right,
    #6666ff,
    #0099ff,
    #00ff00,
    #ff3399,
    #6666ff
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 3s ease-in-out infinite;
  background-size: 400% 100%;

  @keyframes rainbow_animation {
    0%,
    100% {
      background-position: 0 0;
    }

    50% {
      background-position: 100% 0;
    }
  }
`;

const GlobalJackpotDisplay = ({ jackpotAmount, onClick }) => {
  const [jackpotAnimationState, setJackpotAnimationState] = useState("initial");

  const jackpotVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.2 },
    tap: { scale: 0.8 },
    updated: {
      scale: [1, 1.2, 1],
      transition: { duration: 0.4 },
    },
  };

  useEffect(() => {
    // Trigger the 'updated' animation when jackpotAmount changes
    setJackpotAnimationState("updated");
  }, [jackpotAmount]);

  // Fallback to display "0.00" if jackpotAmount is null or undefined
  const displayAmount =
    jackpotAmount != null ? jackpotAmount.toFixed(2) : "0.00";

  return (
    <motion.div
      variants={jackpotVariants}
      initial="initial"
      animate={jackpotAnimationState}
      whileHover="hover"
      whileTap="tap"
      onAnimationComplete={() => {
        if (jackpotAnimationState === "updated") {
          setJackpotAnimationState("initial");
        }
      }}
      onClick={onClick}
    >
      <GlobalJackpotDisplayWrapper>
        GLOBAL JACKPOT {displayAmount} SOL
      </GlobalJackpotDisplayWrapper>
    </motion.div>
  );
};

export default GlobalJackpotDisplay;
