import React, { createContext, useState } from "react";
import matrix from "react95/dist/themes/matrix";
import original from "react95/dist/themes/original";
import blue from "react95/dist/themes/blue";
import pamelaAnderson from "react95/dist/themes/pamelaAnderson";
import olive from "react95/dist/themes/olive";
import tokyoDark from "react95/dist/themes/tokyoDark";
import polarized from "react95/dist/themes/polarized";
import tooSexy from "react95/dist/themes/tooSexy";
import modernDark from "react95/dist/themes/modernDark";
import denim from "react95/dist/themes/denim";
import azureOrange from "react95/dist/themes/azureOrange";
import theSixtiesUSA from "react95/dist/themes/theSixtiesUSA";
import hotChocolate from "react95/dist/themes/hotChocolate";
import violetDark from "react95/dist/themes/violetDark";

export const ThemeContext = createContext();

const THEMES = {
  matrix,
  original,
  pamelaAnderson,
  blue,
  olive,
  tokyoDark,
  polarized,
  tooSexy,
  modernDark,
  denim,
  azureOrange,
  theSixtiesUSA,
  hotChocolate,
  violetDark,
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(THEMES.original); // Default theme

  const changeTheme = (newTheme) => {
    setTheme(THEMES[newTheme]);
    // Remove the axios.put call here
  };

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
